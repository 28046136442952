import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCloseDropdown, toggleMobileNav } from "../../redux/actions";
import "./Product.css";
import plugLogo from "../../assets/smazer/smazer device.png";
// import smazerLogo from "../../assets/productsPage/smazer_header_bg.png";
import smazerLogo from "../../assets/smazer_device_changed.png";
import smazerEmpty from "../../assets/easyInstallStepOne.png";
import smazerStepTwo from "../../assets/productsPage/step_2_img.png";
import smazerStepThree from "../../assets/smazer_easy_install.png";
import smazerStepFour from "../../assets/productsPage/step_4_img.png";
import numberOne from "../../assets/productsPage/one.png";
import numberTwo from "../../assets/productsPage/two.png";
import numberThree from "../../assets/productsPage/three.png";
import numberFour from "../../assets/productsPage/four.png";
import energyLogo from "../../assets/productsPage/energy.png";
import temperatureLogo from "../../assets/productsPage/carbon_soil-temperature.png";
import convenienceLogo from "../../assets/productsPage/convience.png";
import securityLogo from "../../assets/productsPage/security.png";
import innovationLogo from "../../assets/productsPage/iinovation.png";
import durabilityLogo from "../../assets/compatability_icon.png";
import appleLogo from "../../assets/productsPage/apple_ios.png";
import playStoreLogo from "../../assets/productsPage/play_store.png";
import customerComma from "../../assets/productsPage/customer_review_coma.png";
import smazerfeaturebg from "../../assets/smazer_background.png";
import productAppBg from "../../assets/productsPage/featuredMobileApps.png";
// import oneAppBg from "../../assets/productsPage/one_app_img.png";
import FAQ from "./Faq";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import DropDownProducts from "../../core/constants/DropdownProducts";
import AlternateFooter from "../landingPage/AlternateFooter";
import PublicHeader from "../../core/constants/PublicHeader";
import CombinedSmazerColor from "../3dObjectFile(Threejs)/SplineViewer";
import {
 SMAZER_APP_STORE,
 SMAZER_CUSTOMER_REVIEWS,
 SMAZER_PLAY_STORE,
} from "../../core/constants/Constant";
import smazerScreenShotOne from "../../assets/app_screenshots/smazer_app/screenshot_one.jpg";
import smazerScreenShotTwo from "../../assets/app_screenshots/smazer_app/screenshot_two.jpg";
import smazerScreenShotThree from "../../assets/app_screenshots/smazer_app/screenshot_three.jpg";
import smazerScreenShotFour from "../../assets/app_screenshots/smazer_app/screenshot_four.jpg";
import ProductsScreenShots from "./ProductsScreenShots";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";

function SmazerProductPage() {
 const dropdownRef = useRef(null);
 const [activeBorder, setActiveBorder] = useState("White");
 const [loading, setLoading] = useState(true);
 const isDropDownActive = useSelector((state) => state.isProductDropdown);
 const [currentIndex, setCurrentIndex] = useState(0);
 const [images] = useState([
  smazerScreenShotOne,
  smazerScreenShotTwo,
  smazerScreenShotThree,
  smazerScreenShotFour,
 ]);
 const [isLoading, setIsLoading] = useState(true);
 const [showFaqs, setShowFaqs] = useState(false);
 const triggerDropdownFaq = () => {
  setShowFaqs(!showFaqs);
 };

 const navigate = useNavigate();
 const location = useLocation();
 useEffect(() => {
  const timeout = setTimeout(() => {
   setIsLoading(false);
  }, 3000);

  return () => clearTimeout(timeout);
 }, []);
 const [currentImage, setCurrentImage] = useState(images[currentIndex]);
 const dispatch = useDispatch();

 const routeName = location.pathname.split("/").pop();

 const navigateToContactUs = (contactUsUrl) => {
  navigate(contactUsUrl);
 };

 const handleBorderClick = (border) => {
  setLoading(true);
  setActiveBorder(border);
  console.log(border);
 };
 useEffect(() => {
  const handleClickOutside = (event) => {
   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    // Click outside of dropdown, close it
    // dispatch(toggleCloseDropdown());
    setShowFaqs(false);
    console.log("dropdown closed");
   }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
   document.removeEventListener("mousedown", handleClickOutside);
  };
 }, [dispatch]);

 useEffect(() => {
  console.log(routeName);
  const intervalId = setInterval(() => {
   setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, 4000);
  simulateLoading();
  setCurrentImage(images[currentIndex]);
  // Cleanup function to clear interval when component unmounts
  return () => {
   clearInterval(intervalId);
  };
 }, [images.length, location.pathname, activeBorder, currentIndex, images]); // Re-run effect if images length changes

 useEffect(() => {
  window.scrollTo(0, 0);
 }, []);

 const simulateLoading = () => {
  setTimeout(() => {
   setLoading(false);
  }, 2000);
 };

 useEffect(() => {
  return () => {
   dispatch(toggleCloseDropdown());
  };
 }, [dispatch]);

 const scrollToDiv = () => {
  const targetDiv = document.getElementById("product_purchase"); // Replace with the actual ID of your target div
  if (targetDiv) {
   targetDiv.scrollIntoView({ behavior: "smooth" });
  }
 };

 const chunkArray = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
   chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
 };

 const chunkedReviews = chunkArray(SMAZER_CUSTOMER_REVIEWS, 3);

 return (
  <React.Fragment>
   <div id="smazer_product_header_top">
    <PublicHeader />
    {isDropDownActive && <DropDownProducts />}
    <div id="product_background_radial" className="">
     <div className="container-fluid prod-smazer">
      <div className="col-md-5 box-product">
       <div className="col-md-12 smazer_title">SmaZer</div>
       <div className="col-md-12">
        <div className="col-md-12 smazer_info text-white mx-4">
         The best solution to make your Geyser <span className="smart_name">smart</span>
         <p className="col-md-10 smart_sub_info">
          India’s <span className="first_name">first</span> smart water heater controller
         </p>
        </div>
        <div className="col-md-11 bgx text-white mx-4">
         <div
          className="p-3 why_smazer d-flex justify-content-center align-items-center rounded-3"
          style={{ width: "90%" }}
         >
          <div className="col-md-11 smazer_contents">
           <div className="why_smazer_title">Why SmaZer?</div>
           <div className="why_smazer_info mt-4">
            SmaZer helps you save money by reducing your electricity and water consumption.
            With SmaZer, you can control and monitor your water heater from your smartphone or
            voice assistant. Do you want to save money and energy while enjoying hot water
            anytime you want? Then SmaZer is your answer
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div className="col-md-7 box-product mt-4">
       <div className="col-md-12 d-flex justify-content-end">
        <img src={smazerLogo} className="img-fluid" alt="Your Image Alt Text" />
       </div>
       <div className="col-md-8 d-flex justify-content-end align-items-center">
        <button className="btn btn-primary buy_now_button" onClick={scrollToDiv}>
         Buy Now
        </button>
       </div>
      </div>
     </div>
     <div className="container d-flex flex-column justify-content-end align-items-center easy_installation_lines mt-4 mb-5">
      <div className="text-center easy_installation">Easy Installation</div>
      <div className="text-center easy_installation_info">
       You are just 4 steps away to transform your old Geyser into smart Geyser
      </div>
     </div>
    </div>
    {/* Mobile View */}
    <div className="container-fluid mt-5 mb-5 easy_install_mobile">
     <div className="col-md-12 d-flex justify-content-between align-items-center flex-row">
      <div className="col-md-4">
       <div className="col-md-2 d-flex justify-content-center flex-column align-items-center part_one_number_mobile">
        <img className="img-fluid" src={numberOne} height={80} width={80} />
       </div>
      </div>
      <div className="col-md-8">
       <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
        <img
         src={smazerEmpty}
         className="img-fluid"
         style={{
          maxWidth: "100%",
          height: "auto",
          width: "412px",
          maxHeight: "235px",
         }}
        />
       </div>
       <div className="col-md-5 d-flex justify-content-start align-items-center part_one_info">
        Install the smaZer sensor unit in the Geyser outlet
       </div>
      </div>
     </div>
     <div className="col-md-12 d-flex justify-content-between align-items-center flex-row">
      <div className="col-md-4">
       <div className="col-md-2 d-flex justify-content-center align-items-center part_two_number_mobile">
        <img className="img-fluid" src={numberTwo} height={80} width={80} />
       </div>
      </div>
      <div className="col-md-8">
       <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
        <img
         src={smazerStepTwo}
         className="img-fluid"
         style={{
          maxWidth: "100%",
          height: "auto",
          width: "412px",
          maxHeight: "235px",
         }}
        />
       </div>
       <div className="col-md-5 d-flex justify-content-end align-items-center part_one_info">
        Plug your smaZer socket into your wall's electrical outlet
       </div>
      </div>
     </div>
     <div className="col-md-12 d-flex justify-content-between align-items-center flex-row">
      <div className="col-md-4">
       <div className="col-md-2 d-flex justify-content-center align-items-center part_three_number_mobile">
        <img className="img-fluid" src={numberThree} height={80} width={80} />
       </div>
      </div>
      <div className="col-md-8">
       <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
        <img
         src={smazerStepFour}
         className="img-fluid"
         style={{
          maxWidth: "100%",
          height: "auto",
          width: "412px",
          maxHeight: "235px",
         }}
        />
       </div>
       <div className="col-md-5 d-flex justify-content-start align-items-center part_one_info">
        Plug your smaZer socket into your wall's electrical outlet
       </div>
      </div>
     </div>
     <div className="col-md-12 d-flex justify-content-between align-items-center flex-row">
      <div className="col-md-4">
       <div className="col-md-2 d-flex justify-content-center align-items-center part_four_number_mobile">
        <img className="img-fluid" src={numberFour} height={80} width={80} />
       </div>
      </div>
      <div className="col-md-8 mt-3 mb-3">
       <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
        <img
         src={smazerStepThree}
         className="img-fluid"
         style={{
          maxWidth: "100%",
          height: "auto",
          width: "412px",
          maxHeight: "235px",
         }}
        />
       </div>
       <div className="col-md-5 d-flex justify-content-start align-items-center part_one_info">
        Install the smaZer App in your smartphone and pair with the device
       </div>
      </div>
     </div>
    </div>
    {/* Desktop View */}
    <div className="col-md-12 easy_install_desktop mb-5">
     <div className="col-md-12 d-md-flex justify-content-center align-items-center">
      <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
       <img
        src={smazerEmpty}
        className="img-fluid"
        style={{
         maxWidth: "100%",
         height: "auto",
         width: "412px",
         maxHeight: "235px",
        }}
       />
      </div>
      <div className="col-md-2 d-flex justify-content-center flex-column align-items-center part_one_number">
       <img className="img-fluid" src={numberOne} height={80} width={80} />
      </div>
      <div className="col-md-5 d-flex justify-content-start align-items-center part_one_info">
       Install sensor device in the Geyser
      </div>
     </div>
     <div className="col-md-12 d-md-flex justify-content-center align-items-center">
      <div className="col-md-5 d-flex justify-content-end align-items-center part_one_info">
       Plug your smazer into your geyser outlet.
      </div>
      <div className="col-md-2 d-flex justify-content-center align-items-center part_two_number">
       <img className="img-fluid" src={numberTwo} height={80} width={80} />
      </div>
      <div className="col-md-5 d-flex justify-content-start align-items-center part_one_img">
       <img
        src={smazerStepTwo}
        className="img-fluid"
        style={{
         maxWidth: "100%",
         height: "auto",
         width: "412px",
         maxHeight: "235px",
        }}
       />
      </div>
     </div>
     <div className="col-md-12 d-md-flex justify-content-center align-items-center">
      <div className="col-md-5 d-flex justify-content-end align-items-center part_one_img">
       <img
        src={smazerStepFour}
        className="img-fluid"
        style={{
         maxWidth: "100%",
         height: "auto",
         width: "412px",
         maxHeight: "235px",
        }}
       />
      </div>
      <div className="col-md-2 d-flex justify-content-center align-items-center part_three_number">
       <img className="img-fluid" src={numberThree} height={80} width={80} />
      </div>
      <div className="col-md-5 d-flex justify-content-start align-items-center part_one_info">
       Insert your Geyser's plug into the smaZer socket
      </div>
     </div>
     <div className="col-md-12 d-md-flex justify-content-center align-items-center">
      <div className="col-md-5 d-flex justify-content-end align-items-end part_one_info">
       <div style={{ width: "60vh", textAlign: "right" }}>
        Install the smaZer App in your smartphone and pair with the device
       </div>
      </div>
      <div className="col-md-2 d-flex justify-content-center align-items-center part_four_number">
       <img className="img-fluid" src={numberFour} height={80} width={80} />
      </div>
      <div className="col-md-5 d-flex justify-content-start align-items-center part_one_img">
       <img
        src={smazerStepThree}
        className="img-fluid"
        style={{
         maxWidth: "100%",
         height: "auto",
         width: "452px",
         maxHeight: "255px",
        }}
       />
      </div>
     </div>
    </div>
    <section className="col-md-12 d-md-flex justify-content-center flex-column align-items-center">
     <div
      className="col-md-12 d-flex justify-content-center align-items-center"
      style={{ position: "relative" }}
     >
      <div className="text-center easy_installation features_align m-0">Features</div>
     </div>
     <div
      className="col-md-11 d-flex justify-content-start align-items-center"
      // style={{ marginLeft: "30px" }}
     >
      <div className="col-md-11 d-flex justify-content-center flex-column align-items-center">
       <img
        src={smazerfeaturebg}
        className="img-fluid products_features_background"
        alt="Smazer Features Background"
       />
      </div>
     </div>
    </section>
    {/* <div
          className="container-fluid border"
          style={{ height: "600px", background: "transparent" }}
        >
          <SplineViewer />
          <ProductCanvas />
        </div> */}
    <section className="col-md-12 d-md-flex justify-content-center flex-column align-items-center">
     <div className="text-center easy_installation">Benefits</div>
     <div
      className="col-md-10 d-md-flex justify-content-center flex-column p-2"
      id="benefit_section"
     >
      <div className="col-md-12 d-md-flex justify-content-evenly text-white mt-5 benefits_cont">
       <div className="col-md-3 text-center">
        <img className="img-fluid" src={energyLogo} height={48} width={40} />
        <div className="benefits_title">Energy Saving</div>
        <div className="benefits_info">
         Schedules, timers, auto shut-off functions, energy usage tracking & much more helps
         you get insights and recommendations for optimized consumption
        </div>
       </div>
       <div className="col-md-3 text-center">
        <img src={temperatureLogo} height={38} width={34} className="img-fluid" />
        <div className="benefits_title">Comfort</div>
        <div className="benefits_info">
         SmaZer ensures you stay on top of your electricity and water bill. Provides
         recommendation on the optimal temperature and heating duration ensuring your comfort
        </div>
       </div>
       <div className="col-md-3 text-center">
        <img src={convenienceLogo} height={31} width={31} className="img-fluid" />
        <div className="benefits_title">Convenience</div>
        <div className="benefits_info">
         Use smazer app to control your geyser from anywhere. Handsfree control using voice
         commands. Group multiple geysers and control them centrally.
        </div>
       </div>
      </div>
      <div className="col-md-12 d-md-flex justify-content-evenly mb-5 text-white benefits_cont">
       <div className="col-md-3 text-center">
        <img src={durabilityLogo} height={37} width={40} className="img-fluid" />
        <div className="benefits_title">Compatibility</div>
        <div className="benefits_info">
         Compatible with all types of Geysers, like instant & storage type Geysers. It is easy
         to install and does not require any drilling or electrical operation. Just plug and
         play!
        </div>
       </div>
       <div className="col-md-3 text-center">
        <img src={securityLogo} height={43} width={28} className="img-fluid" />
        <div className="benefits_title">Security</div>
        <div className="benefits_info">
         Protects your geyser from power surges and alerts you if there is any unusual activity
         or maintenance issue
        </div>
       </div>
       <div className="col-md-3 text-center">
        <img src={innovationLogo} height={40} width={33} className="img-fluid" />
        <div className="benefits_title">Innovation</div>
        <div className="benefits_info">
         Future-proof design that allows new features to be introduced without any hardware
         changes. Equipped with revolutionary AI Technology
        </div>
       </div>
      </div>
     </div>
    </section>
    <section className="col-md-12 mt-5 d-md-flex justify-content-center flex-column align-items-center">
     {/* <div className="text-center smazer_app_title">Smazer App</div> */}
     <div className="text-center one_app_title_app">
      <span className="one_app_text_app">SmaZer</span> App
     </div>
     <div className="container-fluid d-flex justify-content-center flex-column align-items-center">
      <img
       src={productAppBg}
       className="img-fluid product_app_features_background"
       alt="Smazer App Background"
      />
     </div>
     <div className="col-md-3 d-flex justify-content-evenly">
      <a
       className="col-md-8 d-flex justify-content-center apple_ios glow-button"
       href={SMAZER_APP_STORE}
       target="_blank"
      >
       <div className="col-md-9 d-flex justify-content-center">
        <div className="col-md-4 d-flex justify-content-center align-items-center">
         <img src={appleLogo} width={24} height={26} className="img-fluid" />
        </div>
        <div className="col-md-11 d-flex justify-content-center flex-column">
         <div className="col-md-12 download_from_text">Download From</div>
         <div className="col-md-12 apple_store_text">APPLE APP STORE</div>
        </div>
       </div>
      </a>
      <a
       className="col-md-8 d-md-flex justify-content-center play_store glow-button"
       href={SMAZER_PLAY_STORE}
       target="_blank"
      >
       <div className="col-md-9 d-flex justify-content-center">
        <div className="col-md-4 d-flex justify-content-center align-items-center">
         <img src={playStoreLogo} width={23} height={22} className="img-fluid" />
        </div>
        <div className="col-md-12 d-flex justify-content-center flex-column">
         <div className="download_from_text">Download From</div>
         <div className="google_play_text">GOOGLE PLAY STORE</div>
        </div>
       </div>
      </a>
     </div>
    </section>
    {/* Desktop View */}
    <section className="col-md-12 mt-5 d-md-flex justify-content-center flex-column align-items-center smazer_product_screen_slider">
     <div className="text-center one_app_title">
      <span className="one_app_text">One App</span> Endless Possibilities
     </div>
     <div className="background_container mt-5 mb-5">
      <div className="background_image_smazer d-flex justify-content-center align-items-center">
       <ProductsScreenShots screenShots={currentImage} />
      </div>
     </div>
    </section>
    {/* Mobile View */}
    <section className="col-md-11 d-md-none d-flex justify-content-center flex-column align-items-center mobile_view_screen mb-5">
     <div className="text-center one_app_title mt-3">
      <span className="one_app_text">One App</span> Endless Possibilities
     </div>
     {/* <div
            className="col-9 background-image-mobile"
            style={{ height: "60vh" }}
          > */}
     <ProductsScreenShots screenShots={currentImage} />
     {/* </div> */}
    </section>
    <section className="col-12 d-flex justify-content-center mt-3" id="product_purchase">
     <div className="col-md-11 d-md-flex justify-content-center flex-row smazer_details_info mb-5">
      {/* <div className="col-md-4 d-flex justify-content-center align-items-start">
              <div
                className="container-fluid mobile_view_3d"
                style={{ height: "60%", background: "transparent" }}
              >
                {
                  <CombinedSmazerColor
                    colorType={activeBorder}
                    setLoading={setLoading}
                  />
                }
                {loading && (
                  <div className="col-12 fs-5 text-white d-flex justify-content-center align-items-center">
                    Loading...
                  </div>
                )}
              </div>
            </div> */}
      <div className="col-md-4 d-flex justify-content-center align-items-start mt-5">
       <div
        className="container-fluid spline_3d"
        style={{ height: "60%", background: "transparent" }}
       >
        {<CombinedSmazerColor colorType={activeBorder} setLoading={setLoading} />}
        {loading && (
         <div className="col-12 fs-5 text-white d-flex justify-content-center align-items-center">
          Loading...
         </div>
        )}
       </div>
      </div>
      <div className="col-md-8 p-1 d-md-flex justify-content-center align-items-center flex-column text-white mt-5 mb-5">
       <div className="col-md-11 mb-3">
        <div className="product_names">Smazer</div>
        <div className="product_name_info">Water heater companion</div>
        <div className="col-md-4 col-sm-8 d-flex justify-content-between align-items-center">
         {/* <div className="product_prices">Rs 3799</div>
                  <div className="price_slashed">Rs 5000</div> */}
        </div>
       </div>
       <div className="col-md-11 product_details_listing">
        <ul className="product_listing_details mt-3">
         <li>
          SmaZer is a cost-effective solution that lets you enjoy the smart features of new
          Geyser without replacing your old ones. The price of SmaZer is just a fraction of
          what you would pay for buying a new smart Geyser or exchanging your old one
         </li>
         <li style={{ width: "90%" }}>
          SmaZer is a durable product that comes with a 1-year warranty. It is made in India
          with high-quality materials and components. It is CE certified and meets all the
          safety standards
         </li>
         <li>Space-saving design</li>
         <li>Colorful LED indicators</li>
         <li>
          ABS FR grade enclosure passed 930°C in glow wire testing – No more worries of fire
          hazards{" "}
         </li>
        </ul>
        <div className="col-md-11 mx-3">
         <div className="d-md-flex justify-content-start align-items-center">
          <div className="col-md-12">
           <div className="product_color_text">Color: {activeBorder}</div>
          </div>
         </div>
         <div className="col-md-5 d-flex justify-content-start align-items-center gap-2 pt-2">
          <div
           className={`White ${activeBorder === "White" ? "actives" : ""}`}
           onClick={() => handleBorderClick("White")}
          >
           <div className="color_section_one"></div>
          </div>
          <div
           className={`Blue ${activeBorder === "Blue" ? "actives" : ""}`}
           onClick={() => handleBorderClick("Blue")}
          >
           <div className="color_section_two"></div>
          </div>
          <div
           className={`Green ${activeBorder === "Green" ? "actives" : ""}`}
           onClick={() => handleBorderClick("Green")}
          >
           <div className="color_section_three"></div>
          </div>
          <div
           className={`Grey ${activeBorder === "Grey" ? "actives" : ""}`}
           onClick={() => handleBorderClick("Grey")}
          >
           <div className="color_section_four"></div>
          </div>
         </div>
         {/* <div className="choose_quantity">
                    <div className="quantity_text">Quantity</div>
                    <div className="col-md-3 quantity_btns d-flex justify-content-between align-items-center mt-2">
                      <button
                        className="col-md-3 d-flex justify-content-center align-items-center quantity_button_select"
                        onClick={handleDecrement}
                        disabled={quantity === 1}
                      >
                        <FaMinus color="#3a3a3a" />
                      </button>
                      <div className="quantitiy_number">{quantity}</div>
                      <button
                        className="col-md-3 d-flex justify-content-center align-items-center quantity_button_select"
                        onClick={handleIncrement}
                        disabled={quantity === 4}
                      >
                        <FaPlus color="#3a3a3a" />
                      </button>
                    </div>
                  </div> */}
        </div>
        <div className="col-md-12 mt-4 mx-3">
         {/* <div className="col-md-11 d-flex justify-content-center order_details">
                                        <div className="col-md-11">
                                            <div className="col-md-12 d-flex justify-content-between p-3">
                                                <div className="col-md-8 product_orders_text d-flex justify-content-start align-items-center">Smazer *{quantity} :</div>
                                                <div className="col-md-4 d-flex justify-content-center align-items-center product_orders_price">Rs {productPrice}</div>
                                            </div>
                                            <div className="col-md-12 d-flex justify-content-between p-3">
                                                <div className="col-md-8 product_total d-flex justify-content-start align-items-center">Total :</div>
                                                <div className="col-md-4 d-flex justify-content-center align-items-center total_order_price">Rs {calculateTotal()}</div>
                                            </div>
                                        </div>
                                    </div> */}
         <div className="mt-3">
          <button
           className="col-md-11 btn btn-primary buy_now_button buy_button_text"
           onClick={() => {
            navigateToContactUs(`/contact-us`);
           }}
          >
           Contact Us
          </button>
         </div>
        </div>
       </div>
      </div>
     </div>
    </section>
    <div className="col-md-12 col-sm-12 d-md-flex justify-content-center flex-column align-items-center">
     <div className="col-md-6 col-sm-12 text-white d-flex justify-content-center align-items-center flex-column">
      <div className="customers_review_text">Customers Reviews</div>
      <div className="customers_review_info text-center">
       Let's hear what our customers says about the product
      </div>
     </div>
     <Swiper
      direction="horizontal"
      navigation={true}
      modules={[Navigation]}
      className="mySwiper"
      style={{ width: "90%" }}
     >
      {chunkedReviews.map((reviewChunk, index) => (
       <SwiperSlide key={index} className="container-fluid p-5 d-flex justify-content-center">
        <div className="row d-md-flex justify-content-center align-items-center">
         {reviewChunk.map((review, subIndex) => (
          <div key={subIndex} className="col-12 col-md-4">
           <div className="customer_review_smazer d-flex justify-content-center align-items-center text-white">
            <div className="col-md-2 review_logo">
             <img src={customerComma} className="img-fluid" alt="Review logo" />
            </div>
            <div
             className="col-md-10 d-flex justify-content-evenly flex-column align-items-center tablet_view"
             style={{ height: "28vh" }}
            >
             <div className="customer_review_user text-center">{review.customerName}</div>
             <div
              className="col-md-12 col-sm-12 customer_review_info text-center tablet_view"
              style={{ height: "50%", width: "100%" }}
             >
              {review.review}
             </div>
            </div>
           </div>
          </div>
         ))}
        </div>
       </SwiperSlide>
      ))}
      {/* <SwiperSlide className="container-fluid p-5 d-flex justify-content-center">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="customer_review d-flex justify-content-center align-items-center text-white">
                    <div className="col-md-2 review_logo">
                      <img src={customerComma} className="img-fluid" />
                    </div>
                    <div
                      className="col-md-10 d-flex justify-content-evenly flex-column align-items-center"
                      style={{ height: "28vh" }}
                    >
                      <div className="customer_review_user text-center">
                        Dinesh Ramalingam
                      </div>
                      <div
                        className="col-md-12 col-sm-12 customer_review_info text-center"
                        style={{ height: "60%", width: "90%" }}
                      >
                        SmaZer is a lifesaver! Protects my water heater from
                        surges and sends alerts for preventive maintenance.
                        Essential for a worry-free and efficient hot water
                        experience!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="customer_review d-flex justify-content-center align-items-center text-white">
                    <div className="col-md-2 review_logo">
                      <img src={customerComma} className="img-fluid" />
                    </div>
                    <div
                      className="col-md-10 d-flex justify-content-evenly flex-column align-items-center"
                      style={{ height: "28vh" }}
                    >
                      <div className="customer_review_user text-center">
                        Meera Gupta
                      </div>
                      <div
                        className="col-md-12 col-sm-12 customer_review_info text-center"
                        style={{ height: "60%" }}
                      >
                        SmaZer does it all - surge protection, preventive
                        alerts, and smart control. My water heater's best
                        friend! Recommending for a secure and stress-free home.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="customer_review d-flex justify-content-center align-items-center text-white">
                    <div className="col-md-2 review_logo">
                      <img src={customerComma} className="img-fluid" />
                    </div>
                    <div
                      className="col-md-10 d-flex justify-content-evenly flex-column align-items-center"
                      style={{ height: "28vh" }}
                    >
                      <div className="customer_review_user text-center">
                        Raghul Singh
                      </div>
                      <div
                        className="col-md-12 col-sm-12 customer_review_info text-center"
                        style={{ height: "60%" }}
                      >
                        SmaZer sets the standard! A brilliant combo for water
                        heater care. A must-have for every home aiming for
                        appliance longevity. 100% recommended
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
      {/* <SwiperSlide className="container-fluid p-5 d-flex justify-content-center">
              <div className="row d-md-flex justify-content-center align-items-center">
                <div className="col-12 col-md-4">
                  <div className="customer_review d-flex justify-content-center align-items-center text-white">
                    <div className="col-md-2 review_logo">
                      <img src={customerComma} className="img-fluid" />
                    </div>
                    <div
                      className="col-md-10 d-flex justify-content-evenly flex-column align-items-center"
                      style={{ height: "28vh" }}
                    >
                      <div className="customer_review_user text-center">
                        Preethi
                      </div>
                      <div
                        className="col-md-12 col-sm-12 customer_review_info text-center"
                        style={{ height: "60%" }}
                      >
                        SmaZer ensures peace of mind! No more waiting for a hot
                        shower. A smartplug that truly understands and cares for
                        my Geyser. Highly recommended!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="customer_review d-flex justify-content-center align-items-center text-white">
                    <div className="col-md-2 review_logo">
                      <img src={customerComma} className="img-fluid" />
                    </div>
                    <div
                      className="col-md-10 d-flex justify-content-evenly flex-column align-items-center"
                      style={{ height: "28vh" }}
                    >
                      <div className="customer_review_user text-center">
                        Thilak Ramanujam
                      </div>
                      <div
                        className="col-md-12 col-sm-12 customer_review_info text-center"
                        style={{ height: "60%" }}
                      >
                        A reliable companion for a hassle-free hot water
                        experience. I used to wait for 30 minutes for taking a
                        shower. Now with Schedule option, I have my hot water
                        ready! Thanks to SmaZer
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
     </Swiper>
    </div>
    <FAQ
     title={"FAQs"}
     subTitle={"We care about your questions"}
     refs={dropdownRef}
     showFaq={showFaqs}
     trigger={triggerDropdownFaq}
    />
   </div>
   <AlternateFooter />
  </React.Fragment>
 );
}

export default SmazerProductPage;
